<template>
  <div>
    <DesignHeader
      :designName="detail.shop_name"
      :showClass="false"
      :showInfoTitle="true"
      :showPhoto="true"
      :userPhoto="''"
    />
    <div class="wrapper-1590 mt-40">
      <RecommendSwiper />
    </div>
    <ProductList />
  </div>
</template>
<script>
import RecommendSwiper from '@/components/RecommendSwiper/index'
import ProductList from '@/components/ProductList/index'
import DesignHeader from '../shop/components/ShopHeader.vue'
import { mapState, mapMutations } from 'vuex'
import { post } from '@/utils/request'
export default {
  components: {
    DesignHeader,
    RecommendSwiper,
    ProductList
  },
  data() {
    return {
      name: '',
      detail: {
        shop_name: '1111'
      }
    }
  },
  created() {
    console.log('this.$router: ', this.$router.currentRoute.params)
  }
}
</script>
<style lang="less" scoped>
.wrapper-1590 {
  max-width: 1590px;
  width: 100%;
  margin: 0 auto;
}
.mt-40 {
  margin-top: 40px;
}
</style>
